@import "includes/variables.less";
@import "includes/bootstrap/mixins.less";

#content{
    margin:@menu-height 0px;
    overflow:auto;
}

.sub-menu-spacer {
    padding-top:@menu-height;
}

.sub-menu{
    min-height: 7.5vh;
    background: @menu-background;
    border:0px;
    position:fixed;
    width:100%;
    z-index:130;
    
    > ul > li{
        min-height: @menu-height;
        a{
            min-height: @menu-height;
            padding-top:2.5vh;
            color: @menu-standard;
        }
        &.active{
            color: @menu-selected;
            a {
                background: @menu-background !important;
            }
            .arrow {
                display: block !important;
            }
        }
    }
    a {
        color: @menu-standard;
        &:hover {
            color: @menu-selected;
        }
    }
    .nav a {
        &.active {
            color: @menu-selected;
        }
        &:hover {
            background: @menu-background !important;
            .arrow {
                display: block !important;
            }
        }
    }
    .arrow {
        display:none;
        border-left: @arrow-size solid transparent;
        border-right: @arrow-size solid transparent;
        border-bottom: @arrow-size solid @gray-lighter;
        width: 0; 
        height: 0;
        position:absolute;
        bottom:0px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    
    .hscroll-wrapper {
        position:relative;
        overflow:hidden;
        padding:1vh;
        height:7.5vh;
    }

    .hscroll-list {
        position:absolute;
        left:0px;
        top:0px;
        min-width:3000px;
        margin-left:0px;
        margin-top:0px;
    }

    .hscroll-list li {
        display:table-cell;
        position:relative;
        text-align:center;
        cursor:grab;
        cursor:-webkit-grab;
        color:#efefef;
        vertical-align:middle;
    }

    .hscroll-list li a {
        height:7.5vh;
        padding-top:2.5vh;
        background: #374a5e !important;
        min-width:80px;
    }

    .hscroll-list li.active .arrow {
        display: block !important;
    }

    .scroller {
        text-align:center;
        cursor:pointer;
        display:none;
        padding:9px 11px 9px 11px;
        padding-top:26px;
        white-space:no-wrap;
        vertical-align:middle;
        height:@menu-height;
        color:#fff;
    }

    .scroller-right{
        float:right;
    }

    .scroller-left {
        float:left;
    }   
    
    #tab-section {
        padding-left:0px !important;
    }
    
    @media only screen and (max-width: 810px) {
        #extras-section .dropdown  {
            display: block;
            margin-top: 15px;
            text-align: right;
        }

        .mrp-container {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .mrp-icon {
            display: none;
        }

        .mrp-monthdisplay {
            text-align: right;
        }

        #analysis-extras {
            .toggle {
                height: 30px !important;
            }
            .dropdown  {
                margin-top: 5px;
            }
            .mrp-container  {
                margin-top: 5px !important;
                margin-bottom: 0px !important;
            }
            .toggle-on {
                top: -3px !important;
            }
        }
    }
    
    @media only screen and (max-width: 455px) {
        #extras-section .dropdown  {
            margin-top: 5px;
        }
    }
    
}